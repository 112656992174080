<!--
  PACKAGE_NAME : src\pages\esp\system\setting\list.vue
  FILE_NAME : list.vue
  AUTHOR : supark
  DATE : 2021-07-06
  DESCRIPTION : 시스템 설정 목록
-->
<template>
  <div class="content-themes">
    <tabs @selectedIndex="tabSelectedIndex">
      <tab :title="category.description" :key="category.id" v-for="category in categoryList">
        <esp-dx-data-grid :data-grid="getDataGrid(category.code)" :ref="getDataGridRef(category.code)" @init-new-row="onInitNewRow" />
      </tab>
    </tabs>
  </div>
</template>

<script>
  import Tab from '@/components/common/tab.vue';
  import Tabs from '@/components/common/tabs.vue';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      Tab,
      Tabs,
    },
    data() {
      return {
        dataGrid: {},
        categoryList: [],
        selectTabIndex: '',
        selectTabValue: '',
      };
    },
    computed: {},
    methods: {
      /**@description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.getSystemSettingCategories().then(res => {
          this.categoryList = res;
          this.categoryList.forEach(category => {
            this.$set(this.dataGrid, `dataGrid_${category.code}`, {
              keyExpr: 'id', // 그리드 키값
              refName: `systemVariableRefName_${category.code}`,
              allowColumnResizing: true, //컬럼 사이즈 허용
              showBorders: false, //border 유무
              showColumnHeaders: true, //컬럼 헤더 유무
              showColumnLines: false, //컬럼 세로선 유무
              showRowLines: true, //컬럼 가로선 유무
              rowAlternationEnabled: false,
              dataSource: [],
              apiActionNm: {
                delete: 'SYSTEM_SETTING_DELETE',
                merge: 'SYSTEM_SETTING_MERGE',
              },
              showActionButtons: {
                select: true,
                delete: true,
              },
              customEvent: {
                initNewRow: true, // 신규 행 추가 이벤트
              },
              grouping: {
                contextMenuEnabled: false,
                autoExpandAll: false,
                allowCollapsing: true,
                expandMode: 'rowClick', // rowClick or buttonClick
              },
              groupPanel: {
                visible: false,
              },
              columnChooser: {
                enabled: false, // 컬럼 Chooser 버튼 사용유무
              },
              loadPanel: {
                enabled: true, // 로딩바 표시 유무
              },
              sorting: {
                mode: 'multiple', // single multiple
              },
              scrolling: {
                // 미사용시 주석처리
                mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
              },
              remoteOperations: {
                // 서버사이드 여부
                filtering: false,
                sorting: false,
                grouping: false,
                paging: false,
              },
              paging: {
                // scrolling 미사용시만 적용됨
                enabled: true,
                pageSize: 10,
                pageIndex: 0, // 시작페이지
              },
              pager: {
                visible: true, //페이저 표시 여부
                showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
                allowedPageSizes: [],
                displayMode: 'compact', //표시 모드 : ['full', 'compact']
                showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
                showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
              },
              filterRow: {
                visible: true,
              },
              headerFilter: {
                visible: true,
              },
              editing: {
                allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
                allowDeleting: false,
                allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
                mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
                startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
                selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
              },
              selecting: {
                mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
                selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
                showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
              },
              columns: [
                {
                  caption: '설정 카테고리',
                  dataField: 'configCategory',
                  alignment: 'left', // left center right
                  visible: false,
                  sortOrder: 'none', // acs desc none
                },
                {
                  caption: '설정 키',
                  dataField: 'configKey',
                  width: '250',
                  alignment: 'left', // left center right
                  visible: true,
                  allowEditing: true,
                  sortOrder: 'none', // acs desc none
                  allowHeaderFiltering: false,
                  requiredRule: {
                    message: '설정 KEY 필수입니다.',
                  },
                },
                {
                  caption: '설정 값',
                  dataField: 'configValue',
                  alignment: 'left',
                  visible: true,
                  allowEditing: true,
                  sortOrder: 'none',
                  allowHeaderFiltering: false,
                  allowGrouping: false,
                  requiredRule: {
                    message: '설정 VALUE 필수입니다.',
                  },
                },
                {
                  caption: '설명',
                  dataField: 'description',
                  alignment: 'left',
                  visible: true,
                  allowEditing: true,
                  sortOrder: 'none',
                  allowHeaderFiltering: false,
                  allowGrouping: false,
                },
              ],
            });
          });
        });
      },
      getDataGrid(code) {
        return this.dataGrid[`dataGrid_${code}`];
      },
      getDataGridRef(code) {
        return `dataGrid_${code}`;
      },
      /** @description: 선택된 탭 index */
      tabSelectedIndex(index) {
        const code = this.categoryList[index].code;

        if (this.$refs[`dataGrid_${code}`][0].totalCount === 0 || this.selectTabIndex !== index) {
          this.selectDataList(code);
        }
        this.selectTabIndex = index;
        this.selectTabValue = code;
      },
      /**
       * 시스템 설정 값 조회
       * @param code
       * @returns {(function(): never)|*|{data, totalCount}}
       */
      selectDataList(code) {
        const vm = this;
        this.dataGrid[`dataGrid_${code}`].dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);
            params = { ...params, ...{ configCategory: code } };

            const payload = {
              actionname: 'SYSTEM_SETTING_LIST',
              data: params,
              useErrorPopup: true,
            };

            let rtnData = {
              data: [],
              totalCount: 0,
            };
            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
            }
            return rtnData;
          },
        });
      },
      /**
       * 시스템 설정 카테고리 조회
       * @returns {Promise<void>}
       */
      async getSystemSettingCategories() {
        let payload = {
          actionname: 'SYSTEM_CATEGORY_LIST',
          data: {},
          useErrorPopup: true,
        };

        let rtnData = [];
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          rtnData = res.data.data;
        }
        return rtnData;
      },
      /**
       * 신규 행 추가 이벤트
       * @param e
       */
      onInitNewRow(e) {
        e.data.configCategory = this.categoryList[this.selectTabIndex].code;
      },
    },
    created() {
      this.createdData();
    },
    mounted() {},
  };
</script>
